<template>
  <div :class="[overrideStyles]">
    <div v-if="$slots.header" class="flex items-center">
      <slot name="header" />
    </div>
    <div class="flex justify-center">
      <div :class="[slotStyles]">
        <slot />
      </div>
    </div>
    <div v-if="$slots.footer" class="flex items-center">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  columns?: '2' | '3' | '4'
  spacing?: string
  overrideStyles?: string
}

const baseStyles = 'items-stretch justify-center w-full'

const props = withDefaults(defineProps<Props>(), {
  columns: '3',
  spacing: 'gap-x-8 gap-y-12',
  overrideStyles: 'max-sm:px-psec max-2xl:px-pcon max-w-page mx-auto w-full',
})

const columnsMap = {
  '2': 'grid grid-cols-1 md:grid-cols-2',
  '3': 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3',
  '4': 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4',
}

const slotStyles: string = [
  columnsMap[props.columns],
  props.spacing,
  baseStyles,
].join(' ')
</script>
